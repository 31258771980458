import { Link } from "@StarberryUtils";
import React, { useState } from "react"
import { Row, Col, Container, Breadcrumb } from "react-bootstrap"
import PlayVideo from "../Play/PlayVideo"
import Slider from "react-slick"

// images
import MemberImg from "../../images/video-placeholder.jpg"
import MemberImgTablet from "../../images/video-placeholder-t.jpg"
import MemberImgDesktop from "../../images/video-placeholder-d.jpg"
import "./CustomerReviews.scss"

import { ImageModule } from "../../modules/Image_Module";
import { youtube_parser } from "../common/utils";

const CustomerReviews = (props) => {
  const [isPlay, setPlay] = useState(false);
  const [youtubeId, setYoutubeId] = useState('');
  const CustomerReviewItems = props.data;

  // const CustomerReviewItems = [
  //   {
  //     CustomerImg: 'MemberImg',
  //     CustomerImgTablet: MemberImgTablet,
  //     CustomerImgDesktop: MemberImgDesktop,
  //     CustomerName: "Halah Mezeh",
  //     CustomerPostion: "Sales",
  //   },
  //   {
  //     CustomerImg: MemberImg,
  //     CustomerImgTablet: MemberImgTablet,
  //     CustomerImgDesktop: MemberImgDesktop,
  //     CustomerName: "Linn Deman",
  //     CustomerPostion: "Lettings",
  //   },
  //   {
  //     CustomerImg: MemberImg,
  //     CustomerImgTablet: MemberImgTablet,
  //     CustomerImgDesktop: MemberImgDesktop,
  //     CustomerName: "Arsalan Khan",
  //     CustomerPostion: "Sales",
  //   },
  //   {
  //     CustomerImg: MemberImg,
  //     CustomerImgTablet: MemberImgTablet,
  //     CustomerImgDesktop: MemberImgDesktop,
  //     CustomerName: "Andrew Jennings",
  //     CustomerPostion: "Sales",
  //   },
  //   {
  //     CustomerImg: MemberImg,
  //     CustomerImgTablet: MemberImgTablet,
  //     CustomerImgDesktop: MemberImgDesktop,
  //     CustomerName: "Alex Melec",
  //     CustomerPostion: "Sales",
  //   },
  //   {
  //     CustomerImg: MemberImg,
  //     CustomerImgTablet: MemberImgTablet,
  //     CustomerImgDesktop: MemberImgDesktop,
  //     CustomerName: "Emily Freeman",
  //     CustomerPostion: "Lettings",
  //   },
  // ]

  const settings = {
    dots: true,
    speed: 800,
    slidesToShow: 2,
    slidesToScroll: 1,
    infinite: false,
    arrows: false,
    mobileFirst: true,
    autoplay: true,
    dots: true,

    responsive: [
      {
        breakpoint: 1199,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: false,
          dots: true,
        },
      },
      {
        breakpoint: 99999,
        settings: "unslick",
      },
    ],
  }

  const openVideo = (video_url) => {
    var youtube_id = video_url && youtube_parser(video_url);
    setYoutubeId(youtube_id);
    setPlay(true);
  }

  //console.log("reviews", props.data)

  return (
    <div className="customer-reviews">
      <Container>
        <Row>
          <Col lg={12}>
            <div className="customer-reviews-block">
               <div className="animated">
                <h2>My Customer Reviews</h2>
               </div>
              <div className="customer-reviews-list">
                 <div className="animated">
                  <Slider {...settings}>
                    {CustomerReviewItems.map((item, index) => {

                      let processedImages = JSON.stringify({});
                      if (item.imagetransforms && item.imagetransforms.User_Photo_Transforms) {
                          processedImages = item.imagetransforms.User_Photo_Transforms;
                      }

                      return(
                      <div className="customer-reviews-item" key={index}>
                        <div className="customer-reviews-video">
                          <picture>
                            {item.User_Photo && <ImageModule ImageSrc={item.User_Photo} imagename={"customer-review.User_Photo.tile_image"} article_id={item.id} imagetransforms={item.ggfx_results} ggfxNew={true} />}
                          </picture>

                          {/* <picture>	
                            <source	
                              media="(min-width:992px)"	
                              srcSet={item.CustomerImgDesktop}	
                            />	
                            <source	
                              media="(min-width:768px)"	
                              srcSet={item.CustomerImgTablet}	
                            />	
                            <img src={item.CustomerImg} alt="" />	
                          </picture> */}

                          {
                            item.Embed_Video_URL && (
                              <span className="btn-play" onClick={e => { openVideo(item.Embed_Video_URL) }}>
                                <i className="icon-play"></i>
                              </span>
                            )
                          }

                        </div>
                        <div className="customer-reviews-info">
                          <strong>{item.Name}</strong>
                          <span>{item.Category}</span>
                        </div>
                      </div>
                    )}
                    )}

                  </Slider>
                 </div>
              </div>
            </div>
          </Col>
        </Row>
        <PlayVideo
          isOpen={isPlay}
          isCloseFunction={setPlay}
          videoId={youtubeId}
          isAutoPlay={1}
        />
      </Container>
    </div>
  )
}

export default CustomerReviews
